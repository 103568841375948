@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0px 15px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.25s;
          transition-duration: 0.25s;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 1; }

.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 30px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  -webkit-perspective: 60px;
          perspective: 60px; }

.hamburger--3dx .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  -webkit-perspective: 60px;
          perspective: 60px; }

.hamburger--3dx-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  -webkit-perspective: 60px;
          perspective: 60px; }

.hamburger--3dy .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateX(-180deg);
          transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  -webkit-perspective: 60px;
          perspective: 60px; }

.hamburger--3dy-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  -webkit-perspective: 60px;
          perspective: 60px; }

.hamburger--3dxy .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateX(180deg) rotateY(180deg);
          transform: rotateX(180deg) rotateY(180deg); }
  .hamburger--3dxy.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  -webkit-perspective: 60px;
          perspective: 60px; }

.hamburger--3dxy-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
          transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(-6px, 0, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(-6px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(-6px, 0, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(-6px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(6px, 0, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(6px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(6px, 0, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(6px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  -webkit-transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  -webkit-transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: translate3d(-6px, -7.5px, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(-6px, -7.5px, 0) rotate(-45deg) scale(0.7, 1);
  -webkit-transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: translate3d(-6px, 7.5px, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(-6px, 7.5px, 0) rotate(45deg) scale(0.7, 1);
  -webkit-transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  -webkit-transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  -webkit-transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: translate3d(6px, -7.5px, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(6px, -7.5px, 0) rotate(45deg) scale(0.7, 1);
  -webkit-transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: translate3d(6px, 7.5px, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(6px, 7.5px, 0) rotate(-45deg) scale(0.7, 1);
  -webkit-transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }
  .hamburger--arrowturn.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
            transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }
  .hamburger--arrowturn.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
            transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
            transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
            transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  -webkit-transition-property: none;
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-duration: 0.13s;
          transition-duration: 0.13s;
  -webkit-transition-delay: 0.13s;
          transition-delay: 0.13s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -20px;
    -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    -webkit-transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg);
  -webkit-transition-delay: 0.22s;
          transition-delay: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-duration: 0.13s;
          transition-duration: 0.13s;
  -webkit-transition-delay: 0.13s;
          transition-delay: 0.13s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -20px;
    -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    -webkit-transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(45deg);
          transform: translate3d(0, -10px, 0) rotate(45deg);
  -webkit-transition-delay: 0.22s;
          transition-delay: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  -webkit-transition-duration: 0.275s;
          transition-duration: 0.275s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    -webkit-transition: opacity 0.125s 0.275s ease;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    -webkit-transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
          transform: translate3d(0, 10px, 0) rotate(135deg);
  -webkit-transition-delay: 0.075s;
          transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
            transform: translate3d(0, -20px, 0) rotate(-270deg);
    -webkit-transition-delay: 0.075s;
            transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  -webkit-transition-duration: 0.275s;
          transition-duration: 0.275s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 10px;
    -webkit-transition: opacity 0.125s 0.275s ease;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 20px;
    -webkit-transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-135deg);
          transform: translate3d(0, 10px, 0) rotate(-135deg);
  -webkit-transition-delay: 0.075s;
          transition-delay: 0.075s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(270deg);
            transform: translate3d(0, -20px, 0) rotate(270deg);
    -webkit-transition-delay: 0.075s;
            transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    -webkit-transition: background-color 0.125s 0.175s ease-in;
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      -webkit-transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
    .hamburger--emphatic .hamburger-inner::after {
      top: 10px;
      right: 0;
      -webkit-transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
  .hamburger--emphatic.is-active .hamburger-inner {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
    background-color: transparent; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -60px;
      top: -60px;
      -webkit-transform: translate3d(60px, 60px, 0) rotate(45deg);
              transform: translate3d(60px, 60px, 0) rotate(45deg);
      -webkit-transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -60px;
      top: -60px;
      -webkit-transform: translate3d(-60px, 60px, 0) rotate(-45deg);
              transform: translate3d(-60px, 60px, 0) rotate(-45deg);
      -webkit-transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    -webkit-transition: background-color 0.125s 0.175s ease-in;
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      -webkit-transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 10px;
      right: 0;
      -webkit-transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
    background-color: transparent; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -60px;
      top: 60px;
      -webkit-transform: translate3d(60px, -60px, 0) rotate(-45deg);
              transform: translate3d(60px, -60px, 0) rotate(-45deg);
      -webkit-transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -60px;
      top: 60px;
      -webkit-transform: translate3d(-60px, -60px, 0) rotate(45deg);
              transform: translate3d(-60px, -60px, 0) rotate(45deg);
      -webkit-transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  -webkit-transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  -webkit-transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px; }
  .hamburger--slider .hamburger-inner::before {
    top: 10px;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
          transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    -webkit-transform: rotate(-45deg) translate3d(-4.28571px, -6px, 0);
            transform: rotate(-45deg) translate3d(-4.28571px, -6px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
            transform: translate3d(0, -20px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 10px;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
          transform: translate3d(0, 10px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    -webkit-transform: rotate(45deg) translate3d(4.28571px, -6px, 0);
            transform: rotate(45deg) translate3d(4.28571px, -6px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(90deg);
            transform: translate3d(0, -20px, 0) rotate(90deg); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  -webkit-transition-duration: 0.22s;
          transition-duration: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    -webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    -webkit-transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  -webkit-transition-delay: 0.12s;
          transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  -webkit-transition-duration: 0.22s;
          transition-duration: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    -webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    -webkit-transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-225deg);
          transform: rotate(-225deg);
  -webkit-transition-delay: 0.12s;
          transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  -webkit-transition: background-color 0s 0.13s linear;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 10px;
    -webkit-transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 20px;
    -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  -webkit-transition-delay: 0.22s;
          transition-delay: 0.22s;
  background-color: transparent; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
            transform: translate3d(0, 10px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-duration: 0.13s;
          transition-duration: 0.13s;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -20px;
    -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    -webkit-transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg);
  -webkit-transition-delay: 0.22s;
          transition-delay: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  -webkit-transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::before {
    -webkit-transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    -webkit-transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  background-color: transparent;
  -webkit-transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::before {
    -webkit-transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    -webkit-transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  background-color: transparent;
  -webkit-transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  -webkit-transition-duration: 0.075s;
          transition-duration: 0.075s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    -webkit-transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    -webkit-transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition-delay: 0.12s;
          transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    -webkit-transition-duration: 0s;
            transition-duration: 0s;
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    -webkit-transition-property: top, opacity;
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    -webkit-transition-property: bottom, -webkit-transform;
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
    transition-property: bottom, transform, -webkit-transform; }

.hamburger--vortex.is-active .hamburger-inner {
  -webkit-transform: rotate(765deg);
          transform: rotate(765deg);
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    -webkit-transition-delay: 0s;
            transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    -webkit-transition-duration: 0s;
            transition-duration: 0s;
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    -webkit-transition-property: top, opacity;
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    -webkit-transition-property: bottom, -webkit-transform;
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
    transition-property: bottom, transform, -webkit-transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-765deg);
          transform: rotate(-765deg);
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    -webkit-transition-delay: 0s;
            transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }

/* Colors */
/* Z indexes */
/* Widths */
@media print {
  .e-grid tr th.e-headercell:first-child,
  .e-grid td.e-rowcell.e-gridchkbox,
  .e-grid .e-table col:first-child {
    display: none; } }

.contents {
  display: inline-block;
  padding: 0 15px; }

.ctrllabel {
  font-weight: 600;
  padding-bottom: 12px; }

.eimg {
  margin: 0;
  padding: 3px 10px 3px 3px;
  border: 0 none;
  width: 60px;
  height: 60px;
  float: left; }

.hideThis {
  display: none !important; }

.fullWidthThis {
  width: 100% !important; }

.ename {
  font-weight: bold;
  padding: 6px 3px 1px 3px; }

.midtext, .lowtext {
  font-size: smaller;
  padding: 3px 3px -1px 0px; }

.old, .old body {
  /***********************************************
    * Page defaults
    ***********************************************/
  background-color: #FFFFFF;
  font-family: "Segoe UI", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.2em;
  color: #333333;
  -webkit-font-smoothing: antialiased;
  /***********************************************
    * Other defaults
    ***********************************************/
  /*
    .e-gridcontent .e-content{
        width:100% !important;
    }
    */
  /* Buttons */
  /***********************************************
    * Paint
    ***********************************************/
  /***********************************************
    * athleteMenu
    ***********************************************/
  /***********************************************
    * Forms
    ***********************************************/
  /***********************************************
    * Töltő animáció - index.html
    ***********************************************/
  /***********************************************
    * Navbar
    ***********************************************/
  /***********************************************
    * Sidebar
    ***********************************************/
  /***********************************************
    * Main contents
    ***********************************************/
  /***********************************************
    * Login
    ***********************************************/
  /***********************************************
    * Sweet alert
    ***********************************************/
  /***********************************************
    * Responsive
    ***********************************************/
  /* col-sm */
  /* col-md */
  /* col-lg */
  /***********************************************
    * Átnézendő
    ***********************************************/
  /*
    #GridEditForm {
        > span {
            width:80% !important;
            float: left;
        }
    }
    */ }
  .old body {
    padding-top: 4rem;
    min-width: 460px;
    position: static !important; }
  .old ::-webkit-scrollbar {
    width: 10px; }
  .old ::-webkit-scrollbar-track {
    background: #333333; }
  .old ::-webkit-scrollbar-thumb {
    background: #3174A8; }
  .old ::-webkit-scrollbar-thumb:hover {
    background: #12629F; }
  .old #newMessage {
    border: none; }
  .old #mainPage-message-wrapper {
    background-color: #ffffff; }
  .old #message-list {
    height: 400px;
    background-color: #fff;
    border-right: 2px solid #eee;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    margin: 0; }
    .old #message-list .message-list-item {
      border-bottom: 1px solid #eee;
      overflow: hidden;
      padding: 0.5em 1em 0.5em 1em;
      border-left: 3px #ffffff solid;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      -webkit-transition: 0.3s all linear;
      transition: 0.3s all linear;
      cursor: default; }
      .old #message-list .message-list-item p:first-of-type {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 15px;
        width: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      .old #message-list .message-list-item.unread {
        border-left-color: #B12B2B; }
      .old #message-list .message-list-item.selected {
        background-color: #BED5E6;
        border-left-color: #12629F;
        padding: 0.5em 1em 0.5em 1.5em;
        -webkit-box-shadow: 5px 10px 18px #888888;
                box-shadow: 5px 10px 18px #888888; }
      .old #message-list .message-list-item:last-of-type {
        border-bottom: 0; }
      .old #message-list .message-list-item:hover {
        background-color: #BED5E6; }
      .old #message-list .message-list-item .message-list-item-date {
        font-weight: bold;
        font-style: italic;
        font-size: 13px; }
      .old #message-list .message-list-item .message-list-item-main {
        width: 100%;
        float: left; }
        .old #message-list .message-list-item .message-list-item-main .message-list-item-header {
          font-weight: bold;
          overflow: hidden;
          max-height: 100px; }
        .old #message-list .message-list-item .message-list-item-main:not(.message-list-item-header) {
          font-weight: normal; }
          .old #message-list .message-list-item .message-list-item-main:not(.message-list-item-header) .message-list-item-date {
            float: right; }
        .old #message-list .message-list-item .message-list-item-main .message-list-item-short-message {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px; }
      .old #message-list .message-list-item .message-list-item-buttons {
        float: left;
        width: 70px;
        margin-left: 1em;
        margin-top: 0.75em; }
        .old #message-list .message-list-item .message-list-item-buttons a {
          font-size: 20px;
          text-align: center;
          width: 35px;
          float: left;
          padding: 0;
          margin: 0; }
  .old #message-detail {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1em 2em; }
  .old [data-content] {
    content: ''; }
  .old a {
    color: #12629F;
    cursor: pointer; }
    .old a:hover {
      color: #3174A8;
      text-decoration: none; }
    .old a:hover, .old a:focus, .old a:active {
      outline: 0;
      text-decoration: none; }
  .old .small-logo {
    height: 55px !important; }
  .old .e-monthappointment {
    height: 1.75em; }
  .old .e-toolbar {
    border: 1px solid #e5e5e5; }
  .old .e-header .e-text {
    line-height: 27px; }
  .old .e-checkbox-wrapper input {
    display: none; }
  .old .slim-grid.e-grid .e-rowcell, .old .slim-grid.e-grid .e-headercell {
    padding: 0.5em 0.7em !important; }
  .old .slim-grid.e-grid .e-summarycell {
    padding: 0.5em 0.25em !important; }
  .old .orientationcss .e-headercelldiv {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    width: 250px !important;
    height: 250px !important;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .old .e-stackedheadercelldiv {
    padding: 5px 0 !important; }
  .old .e-headercelldiv {
    padding: 5px 0 !important; }
  .old .e-monthappointment {
    width: 95% !important; }
    .old .e-monthappointment > div, .old body .e-monthappointment > div {
      padding-left: 7px; }
  .old .e-input {
    padding-left: 15px; }
  .old .e-treeview .e-ul {
    padding-left: 0; }
  .old .e-default {
    background: inherit; }
  .old .e-pager .e-prevpagedisabled, .old .e-pager .e-prevpage, .old .e-pager .e-nextpage, .old .e-pager .e-nextpagedisabled, .old .e-pager .e-lastpagedisabled, .old .e-pager .e-lastpage, .old .e-pager .e-firstpage, .old .e-pager .e-firstpagedisabled {
    background-color: inherit !important; }
  .old .e-pager .e-pagercontainer {
    background-color: inherit !important; }
  .old .e-pager .e-prevpagedisabled, .old .e-pager .e-prevpage, .old .e-pager .e-nextpage, .old .e-pager .e-nextpagedisabled, .old .e-pager .e-lastpagedisabled, .old .e-pager .e-lastpage, .old .e-pager .e-firstpage, .old .e-pager .e-firstpagedisabled {
    background-color: inherit; }
  .old .e-pager .e-prevpagedisabled, .old .e-pager .e-prevpage, .old .e-pager .e-nextpage, .old .e-pager .e-nextpagedisabled, .old .e-pager .e-lastpagedisabled, .old .e-pager .e-lastpage, .old .e-pager .e-firstpage, .old .e-pager .e-firstpagedisabled {
    background-color: inherit; }
  .old .e-pager a:not(.e-currentitem):hover {
    color: inherit; }
  .old .e-pager a.e-currentitem:hover {
    color: white; }
  .old .e-ddl.e-input-group:not(.e-rtl) .e-input-group-icon {
    border: 0; }
  .old .e-grid .e-rowcell.e-updatedtd.e-active {
    color: #333; }
  .old td#annualseason {
    padding: 3px !important; }
    .old td#annualseason input {
      text-align: center; }
  .old section {
    overflow: hidden; }
  .old pre {
    white-space: pre-wrap; }
  .old h1, .old h2 {
    margin: 0.5em 0.25em;
    padding: 0; }
  .old h1 {
    font-size: 3rem;
    line-height: 1.2em; }
  .old h2 {
    font-size: 1.25rem;
    line-height: 1.2em; }
  .old #dateSpan {
    color: #B12B2B; }
  .old .disabled label {
    color: #c8c8c8; }
  .old .sideMenuOpenedButton {
    display: none;
    -webkit-transition: all 0.75s;
    /* Safari */
    transition: all 0.75s; }
  .old .sideMenuOpened {
    left: 0 !important;
    -webkit-transition: all 0.75s;
    /* Safari */
    transition: all 0.75s; }
  .old .sideMenuOpenedContent .mainbar {
    margin-left: 280px !important;
    -webkit-transition: all 0.75s;
    /* Safari */
    transition: all 0.75s; }
  .old .sideMenuClosedContent .mainbar {
    margin-left: 0 !important;
    -webkit-transition: all 0.75s;
    /* Safari */
    transition: all 0.75s; }
  .old .sideMenuOpened .sidebar-filler {
    left: 0 !important;
    -webkit-transition: all 0.75s;
    /* Safari */
    transition: all 0.75s; }
  .old .sideMenuClosedButton {
    display: block;
    -webkit-transition: all 0.75s;
    /* Safari */
    transition: all 0.75s; }
  .old .sideMenuClosed {
    left: -280px !important;
    -webkit-transition: all 0.75s;
    /* Safari */
    transition: all 0.75s; }
  .old .sideMenuClosed .sidebar-filler {
    left: -280px !important;
    -webkit-transition: all 0.75s;
    /* Safari */
    transition: all 0.75s; }
  .old .e-workcells .max-size-workcells {
    height: 100%; }
  .old .css-right-scrollbug .e-scrollcss {
    padding-right: 0; }
  .old .frozenfix .e-frozenheaderdiv .e-headercell {
    height: 150px !important; }
  .old .swal2-overlay {
    z-index: 20000000 !important; }
  .old .swal2-container, .old .swal2-modal {
    z-index: 20000001 !important; }
  .old .swal2-content p {
    text-align: justify;
    padding: 6px; }
  swal2-content .old .swal2-icon.swal2-info, .old swal2-content body .swal2-icon.swal2-info {
    color: #3174A8;
    border-color: #3174A8; }
  .old .swal2-icon.swal2-warning {
    color: #FFAA39;
    border-color: #FFAA39; }
  .old .swal2-icon.swal2-error {
    border-color: #B12B2B; }
    .old .swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
      background-color: #B12B2B; }
  .old .swal2-confirm {
    outline-style: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #FFFFFF !important;
    background-color: #3174A8 !important;
    border: 1px solid #12629F !important;
    border-radius: 5px !important;
    -webkit-transition: background-color 0.3s linear, color 0.3s linear;
    transition: background-color 0.3s linear, color 0.3s linear; }
    .old .swal2-confirm:hover {
      background-color: #FFFFFF !important;
      color: #3174A8 !important; }
  .old .swal2-cancel {
    outline-style: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #FFFFFF !important;
    background-color: #676767 !important;
    border: 1px solid #676767 !important;
    border-radius: 5px !important;
    -webkit-transition: background-color 0.3s linear, color 0.3s linear;
    transition: background-color 0.3s linear, color 0.3s linear; }
    .old .swal2-cancel:hover {
      background-color: #FFFFFF !important;
      color: #676767 !important; }
  .old .e-chkbox-wrap div > span, .old .e-chkbox-wrap .e-button:hover div > span, .old .e-button:hover .e-chkbox-wrap div > span, .old .e-radiobtn-wrap > span, .old body .e-radiobtn-wrap > span, .old .e-button:hover .e-radiobtn-wrap > span, .old .e-button:hover body .e-radiobtn-wrap > span {
    z-index: 0 !important; }
  .old .alert {
    clear: both; }
  .old .btn {
    border-radius: 0 5px 5px 0; }
  .old .btn.btn-primary {
    background: #12629F; }
  .old .btn.btn-primary:hover {
    background: #3174A8; }
  .old .hide-content {
    visibility: hidden; }
  .old .overflow-hidden {
    overflow: hidden; }
  .old a.helpText {
    color: #FFFFFF; }
    .old a.helpText:hover {
      color: #ffffff; }
  .old .scroller-fix > .e-content, .old body .scroller-fix > .e-content {
    overflow: auto;
    width: 100% !important; }
  .old .e-menu .e-list.e-hidden-item {
    display: none !important; }
  .old .e-treeview-wrap {
    border-color: #c8c8c8 !important;
    border: 1px solid;
    -webkit-box-sizing: border-box !important;
            box-sizing: border-box !important;
    overflow: hidden; }
  .old ::-webkit-input-placeholder {
    color: #c8c8c8; }
  .old ::-moz-placeholder {
    color: #c8c8c8; }
  .old :-ms-input-placeholder {
    color: #c8c8c8; }
  .old input:-moz-placeholder {
    color: #c8c8c8; }
  .old .hidedContent {
    margin-top: 3em;
    overflow: hidden;
    width: 100%;
    height: 0;
    /* For Safari 3.1 to 6.0 */
    -webkit-transition-property: height;
    /* Standard syntax */
    transition-property: height; }
  .old .radioBtn {
    width: 3em;
    float: left; }
    .old .radioBtn .labelBtn {
      margin-left: 0.2em; }
  .old .radioBtn2 {
    padding-top: 1.5em;
    padding-left: 1em;
    padding-right: 1.5em;
    width: 7em;
    float: left; }
    .old .radioBtn2 .labelBtn {
      margin-left: 0.3em; }
  .old .e-atc-popup ul li {
    overflow: hidden; }
  .old .e-button span, .old .e-button:hover span {
    color: #FFFFFF; }
  .old .e-dialog.e-js.e-widget-content.e-box,
  .old .trv-page-wrapper.active {
    height: 100% !important; }
  .old .trv-page-wrapper.active .trv-report-page {
    width: auto !important;
    height: auto !important; }
  .old .trv-page-wrapper.active .trv-report-page .sheet {
    -webkit-transform: scale(1, 1) !important;
            transform: scale(1, 1) !important; }
  .old .form-control[disabled], .old input[disabled] {
    cursor: inherit;
    color: black;
    opacity: 1;
    background-color: transparent; }
  .old span.e-disable-wrap span, .old span.e-disable-wrap .e-button:hover span, .old .e-button:hover span.e-disable-wrap span {
    border-color: #e5e5e5; }
  .old .paint button {
    border: 2px solid #FFFFFF; }
    .old .paint button:hover {
      border: 2px solid #1E74A6 !important; }
    .old .paint button:disabled {
      border: 2px solid #FFFFFF;
      opacity: 0.25; }
    .old .paint button.active {
      border: 2px solid #1E74A6;
      opacity: 1; }
    .old .paint button:focus {
      outline: none;
      border: 2px solid #FFFFFF; }
  .old .paint button.white-border {
    border-color: #FFF !important; }
  .old #athleteMenu {
    position: absolute;
    left: -280px;
    top: 52px;
    background-color: #333333;
    z-index: 100;
    width: 280px; }
    .old #athleteMenu a {
      color: #FFFFFF;
      text-decoration: none;
      display: block;
      -webkit-transition: all 0.5s linear;
      transition: all 0.5s linear; }
      .old #athleteMenu a:hover {
        color: #FFFFFF; }
      .old #athleteMenu a.disabled {
        background-color: #676767;
        cursor: not-allowed; }
        .old #athleteMenu a.disabled:hover {
          background-color: #676767; }
    .old #athleteMenu ul {
      padding: 0;
      margin: 0;
      list-style-type: none; }
      .old #athleteMenu ul li {
        list-style-type: none; }
        .old #athleteMenu ul li a {
          padding: 0.75em 1.5em;
          border-top: 1px solid #262626;
          background-color: #333333; }
          .old #athleteMenu ul li a:hover {
            background-color: #3174A8; }
    .old #athleteMenu.is-activate {
      left: 0; }
  .old #printMenu {
    position: absolute;
    right: -280px;
    top: 52px;
    background-color: #333333;
    z-index: 100;
    width: 280px;
    -webkit-transition: all 0.25s linear;
    transition: all 0.25s linear; }
    .old #printMenu a {
      color: #FFFFFF;
      text-decoration: none;
      display: block; }
      .old #printMenu a:hover {
        color: #FFFFFF; }
      .old #printMenu a.disabled {
        background-color: #3174A8;
        cursor: not-allowed; }
    .old #printMenu ul {
      padding: 0;
      margin: 0;
      list-style-type: none; }
      .old #printMenu ul li {
        list-style-type: none; }
        .old #printMenu ul li a {
          padding: 0.75em 1.5em;
          border-top: 1px solid #262626;
          background-color: #333333; }
          .old #printMenu ul li a:hover {
            background-color: #3174A8; }
    .old #printMenu.is-activate {
      right: 0; }
  .old form .form-control, .old form .e-input {
    font-size: 1rem;
    line-height: 1.4em;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .old form .input-group-addon {
    padding: 6px; }
  .old form .form-control:not(.textarea) {
    height: 30px;
    padding: 0 12px; }
  .old form input, .old form textarea {
    border-radius: 0; }
  .old form .button-wrapper {
    float: left;
    padding: 1.2em;
    width: 100%;
    overflow: hidden;
    text-align: center;
    clear: right; }
    .old form .button-wrapper button {
      margin-left: 1.25px;
      margin-right: 1.25px;
      min-width: 100px !important; }
    .old form .button-wrapper.no-margin-top button {
      margin: 0 !important; }
  .old form .image-preview {
    margin: 0 auto;
    overflow: hidden;
    max-width: 23rem;
    width: 200px;
    padding-bottom: 2em; }
  .old form .cts-form-group {
    overflow: hidden;
    margin-bottom: 15px; }
    .old form .cts-form-group label {
      text-align: right;
      font-weight: normal; }
    .old form .cts-form-group.chkbox label {
      text-align: right;
      font-weight: normal;
      float: left; }
    .old form .cts-form-group.chkbox > div {
      float: left;
      padding-left: 1em; }
      .old form .cts-form-group.chkbox > div .e-chkbox-wrap {
        margin-top: 0.25em; }
    .old form .cts-form-group .validation-messages {
      margin-top: 0.25em;
      display: none; }
      .old form .cts-form-group .validation-messages span.error {
        color: #B12B2B; }
    .old form .cts-form-group.required label {
      font-weight: bold; }
      .old form .cts-form-group.required label::after {
        content: '*'; }
    .old form .cts-form-group.error label {
      color: #B12B2B; }
    .old form .cts-form-group.error .validation-messages {
      display: inherit; }
  .old form:not(.filter):not(.popup) .input-group {
    width: 100% !important; }
  .old form:not(.filter):not(.popup) .input-group .input-group-addon {
    border-left-width: 0 !important;
    cursor: pointer; }
  .old form:not(.filter):not(.popup) .button-wrapper {
    padding: 1em; }
  .old form.filter {
    overflow: hidden; }
    .old form.filter .cts-form-group {
      width: 100%; }
      .old form.filter .cts-form-group label {
        width: 100%;
        text-align: left; }
      .old form.filter .cts-form-group > div {
        width: 100%; }
      .old form.filter .cts-form-group.chkbox label {
        position: relative;
        top: 29px;
        left: 23px; }
  .old form.popup .cts-form-group {
    width: 100%; }
    .old form.popup .cts-form-group label {
      width: 100%;
      text-align: left; }
    .old form.popup .cts-form-group > div {
      width: 100%; }
    .old form.popup .cts-form-group.chkbox label {
      text-align: center; }
    .old form.popup .cts-form-group.chkbox > div {
      text-align: center; }
  .old form .e-menu-wrap {
    margin-bottom: 1em; }
    .old form .e-menu-wrap li {
      background-color: #12629F !important;
      color: #FFFFFF !important;
      border: 0; }
      .old form .e-menu-wrap li a {
        color: #FFFFFF !important; }
      .old form .e-menu-wrap li.e-disable-item {
        opacity: 0.5; }
        .old form .e-menu-wrap li.e-disable-item * {
          opacity: 1 !important; }
    .old form .e-menu-wrap #menuEvents {
      border: none; }
  .old .cts-button {
    background-color: #12629F !important;
    color: white !important;
    padding: 0.5em 1em !important;
    border-radius: 5px !important;
    border: 1px solid #12629F;
    -webkit-transition: background-color 0.3s linear, color 0.3s linear;
    transition: background-color 0.3s linear, color 0.3s linear;
    min-width: 100px !important;
    margin: 1.25px 1.25px 0 1.25px; }
    .old .cts-button.auto-width {
      width: auto !important; }
    .old .cts-button.e-disable:hover {
      background-color: #676767 !important;
      color: white !important;
      cursor: not-allowed; }
    .old .cts-button.e-disable {
      background-color: #676767 !important;
      color: white !important;
      border-color: #676767; }
    .old .cts-button:hover {
      background-color: #ffffff !important;
      color: #12629F !important;
      border-color: #12629F !important; }
    .old .cts-button:disabled {
      background-color: #676767 !important;
      color: white !important;
      border-color: #676767 !important;
      cursor: not-allowed; }
  .old .cancel {
    background-color: #B12B2B !important;
    border-color: #B12B2B !important; }
    .old .cancel:hover {
      color: #B12B2B !important;
      border-color: #B12B2B !important; }
  .old .cts-button.widthfull {
    width: 100% !important; }
  .old .e-dropdownlist {
    padding: 0 1em; }
  .old #overlay-container {
    visibility: visible; }
    .old #overlay-container.hide-overlay {
      visibility: hidden; }
      .old #overlay-container.hide-overlay .overlay-background, .old #overlay-container.hide-overlay .overlay-content {
        opacity: 0; }
    .old #overlay-container .overlay-background {
      z-index: 10000;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      -webkit-transition: all 0.5s linear;
      transition: all 0.5s linear; }
    .old #overlay-container .overlay-content {
      position: fixed;
      z-index: 10001;
      text-align: center;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      -webkit-transition: all 0.5s linear;
      transition: all 0.5s linear; }
      .old #overlay-container .overlay-content .page-splash-message, .old #overlay-container .overlay-content .page-spinner-message {
        font-size: 4rem;
        line-height: 1.2em;
        text-shadow: 0.025em 0.025em rgba(0, 0, 0, 0.15);
        text-transform: uppercase;
        color: #3174A8; }
      .old #overlay-container .overlay-content .page-spinner-message {
        font-size: 2rem;
        padding-bottom: 0.5em; }
      .old #overlay-container .overlay-content .page-spinner img {
        width: 8rem; }
  .old .navbar {
    height: 64px;
    margin: 0;
    padding: 0; }
    .old .navbar .dropdown-menu {
      margin: 3px 0 0; }
    .old .navbar .nav .dropdown {
      font-size: 1rem; }
      .old .navbar .nav .dropdown.open > a, .old .navbar .nav .dropdown.open > a:hover, .old .navbar .nav .dropdown.open > a:focus {
        background-color: transparent; }
      .old .navbar .nav .dropdown.open .dropdown-menu {
        white-space: nowrap; }
        .old .navbar .nav .dropdown.open .dropdown-menu > li > a {
          padding: 0.75em 1.25em; }
          .old .navbar .nav .dropdown.open .dropdown-menu > li > a:hover, .old .navbar .nav .dropdown.open .dropdown-menu > li > a:focus, .old .navbar .nav .dropdown.open .dropdown-menu > li > a:active {
            background-color: #3174A8;
            color: #FFFFFF; }
        .old .navbar .nav .dropdown.open .dropdown-menu .divider {
          background-color: #e5e5e5;
          height: 1px;
          margin: 0; }
    .old .navbar .nav > li > a {
      color: #FFFFFF; }
      .old .navbar .nav > li > a:hover, .old .navbar .nav > li > a:focus {
        background-color: inherit; }
      .old .navbar .nav > li > a .img-rounded.profile-image {
        width: 2.5rem;
        height: 2.5rem;
        margin: 0 5px 0 0; }
    .old .navbar i {
      margin-right: 3px; }
    .old .navbar.navbar-inverse {
      background-color: #333333;
      border-color: #262626; }
    .old .navbar .navbar-brand {
      padding: 0;
      margin: 0;
      height: 4rem; }
      .old .navbar .navbar-brand .brand-title {
        line-height: 1.2em;
        color: #FFFFFF; }
  .old .sidebar {
    width: 280px;
    float: left;
    color: #FFFFFF;
    position: relative;
    height: 100%; }
    .old .sidebar a {
      color: #FFFFFF;
      text-decoration: none;
      display: block;
      -webkit-transition: 0.3s all linear;
      transition: 0.3s all linear; }
      .old .sidebar a:hover {
        color: #FFFFFF; }
    .old .sidebar .sidebar-filler {
      z-index: 1;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      background: #333333;
      width: 280px; }
    .old .sidebar .sidebar-dropdown {
      display: none; }
      .old .sidebar .sidebar-dropdown a {
        background-color: #333333;
        padding: 0.5em;
        text-transform: uppercase;
        text-align: center;
        border-top: 1px solid #676767;
        border-bottom: 1px solid #676767; }
    .old .sidebar .sidebar-inner {
      width: 100%;
      position: relative;
      z-index: 60; }
      .old .sidebar .sidebar-inner ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        -webkit-transition: 0.3s all linear;
        transition: 0.3s all linear; }
        .old .sidebar .sidebar-inner ul li {
          list-style-type: none; }
        .old .sidebar .sidebar-inner ul.navi li ul,
        .old .sidebar .sidebar-inner ul.navi li ul li ul {
          display: none;
          background: #262626; }
        .old .sidebar .sidebar-inner ul.navi li a {
          padding: 0.75em 1.0em;
          border-bottom: 1px solid #262626;
          background-color: #333333; }
          .old .sidebar .sidebar-inner ul.navi li a:hover {
            background-color: #3174A8; }
        .old .sidebar .sidebar-inner ul.navi li > ul > li > a {
          background-color: transparent;
          padding-left: 30px;
          border-bottom: 1px solid #333333; }
        .old .sidebar .sidebar-inner ul.navi li > ul > li > ul > li > a {
          background-color: transparent;
          padding: 10px 0;
          padding-left: 50px;
          border-bottom: 1px solid #222; }
        .old .sidebar .sidebar-inner ul.navi li.open > ul,
        .old .sidebar .sidebar-inner ul.navi li.open > ul > li.open > ul {
          display: block; }
        .old .sidebar .sidebar-inner ul.navi li.open > a,
        .old .sidebar .sidebar-inner ul.navi li.current > a,
        .old .sidebar .sidebar-inner ul.navi li.open > ul > li.current > a,
        .old .sidebar .sidebar-inner ul.navi li.open > ul > li.open > a,
        .old .sidebar .sidebar-inner ul.navi li.open > ul > li.open > ul > li.current > a {
          background-color: #12629F; }
  .old .panel-danger {
    border: none; }
    .old .panel-danger .panel-heading {
      color: #FFFFFF;
      background-color: #B12B2B;
      background: linear-gradient(225deg, transparent 15px, #B12B2B 0) top right;
      border: none; }
    .old .panel-danger .panel-body {
      border: 1px solid #e5e5e5; }
  .old .mainbar {
    margin: 0 0 0 280px;
    padding: 2rem 1.5rem;
    background: #FFFFFF; }
    .old .mainbar .row .widget {
      background: #ffffff; }
      .old .mainbar .row .widget .widget-head {
        height: 52px;
        padding: 0.25em 1em;
        margin: 0;
        color: #FFFFFF;
        background: #12629F;
        background: linear-gradient(225deg, transparent 15px, #12629F 0) top right;
        border-bottom: 1px solid black; }
      .old .mainbar .row .widget .widget-content {
        overflow: hidden;
        padding: 1em;
        margin: 0;
        border: 0.1em solid #e5e5e5;
        border-top-width: 0;
        /***********************************************
                    * Syncfusion és Bootstrap elemek
                    ***********************************************/
        /***********************************************
                    * Psychology
                    ***********************************************/
        /***********************************************
                    * Vissza gomb
                    ***********************************************/ }
        .old .mainbar .row .widget .widget-content.form {
          padding-top: 2em; }
        .old .mainbar .row .widget .widget-content form .thumb {
          width: 100%;
          max-width: 200px;
          border: 0.1em solid #676767; }
        .old .mainbar .row .widget .widget-content #Schedule1 div.image {
          text-align: center;
          width: 100%; }
        .old .mainbar .row .widget .widget-content #Schedule1 img {
          width: 30px;
          height: 30px;
          opacity: 0.5; }
        .old .mainbar .row .widget .widget-content #Schedule1 .caption {
          display: block;
          opacity: 0.5;
          overflow: hidden; }
        .old .mainbar .row .widget .widget-content .inline-table {
          table-layout: auto;
          border-collapse: collapse;
          border-spacing: 0;
          padding: 0;
          margin: 0; }
          .old .mainbar .row .widget .widget-content .inline-table tr {
            padding: 0;
            margin: 0; }
            .old .mainbar .row .widget .widget-content .inline-table tr td {
              padding: 0;
              margin: 0;
              border: 0; }
        .old .mainbar .row .widget .widget-content .alert {
          margin-top: 2rem; }
        .old .mainbar .row .widget .widget-content div.attendance-report-div {
          overflow: hidden;
          margin-bottom: 0.25rem; }
          .old .mainbar .row .widget .widget-content div.attendance-report-div p {
            float: left;
            padding-right: 2rem; }
        .old .mainbar .row .widget .widget-content table.attendance-report-table {
          width: 100%;
          margin: 0 0 2rem; }
          .old .mainbar .row .widget .widget-content table.attendance-report-table td, .old .mainbar .row .widget .widget-content table.attendance-report-table th {
            padding: 0.5rem 0.5rem; }
          .old .mainbar .row .widget .widget-content table.attendance-report-table td:first-child {
            font-weight: bold; }
        .old .mainbar .row .widget .widget-content .e-row.e-hover {
          color: #FFFFFF !important; }
          .old .mainbar .row .widget .widget-content .e-row.e-hover td a {
            color: #ffffff; }
        .old .mainbar .row .widget .widget-content .e-alt_row.e-hover {
          color: #FFFFFF !important; }
          .old .mainbar .row .widget .widget-content .e-alt_row.e-hover td a {
            color: #ffffff; }
        .old .mainbar .row .widget .widget-content .cts-block {
          margin-top: 1em;
          border-bottom: 0.1em solid #676767; }
          .old .mainbar .row .widget .widget-content .cts-block:last-child {
            border: none; }
        .old .mainbar .row .widget .widget-content .psycho-test {
          margin: 0 auto; }
          .old .mainbar .row .widget .widget-content .psycho-test .test-header, .old .mainbar .row .widget .widget-content .psycho-test .test-evaluation {
            margin-bottom: 1em; }
          .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body {
            width: 100%;
            margin: 0 auto; }
            .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body .description {
              height: auto; }
            .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table {
              border-collapse: collapse !important;
              margin-bottom: 1em; }
              .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table.pieron-table tr:nth-child(5n) td {
                padding-bottom: 1.5rem; }
              .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table.pieron-table tr:last-child td {
                padding-bottom: 0; }
              .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr {
                background-color: #FFFFFF; }
                .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td {
                  border: 0.025em solid #cccccc; }
                  .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.question-index {
                    width: 2em;
                    text-align: center;
                    height: 100%; }
                  .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.question {
                    width: calc(100% - 12em);
                    padding: 0.2em 1em; }
                  .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer {
                    text-align: center;
                    width: 2em;
                    cursor: pointer; }
                    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer span {
                      display: block;
                      width: 1.6em;
                      height: 1.6em;
                      padding: 0 0 0.1em 0;
                      margin: 0 auto;
                      border: 0.15em solid transparent;
                      border-radius: 100%; }
                    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer.selected span {
                      border-color: #3174A8;
                      border-radius: 100%; }
                    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer.selected-red span {
                      border-color: #B12B2B;
                      border-radius: 100%; }
                    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer.selected-green span {
                      border-color: #4FD258;
                      border-radius: 100%; }
                    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer.selected-minutes span, .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer.selected-minutes-good span, .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer.selected-minutes-bad span {
                      background-color: #cccccc; }
                .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper:nth-of-type(2n) {
                  background-color: #ffffff; }
          .old .mainbar .row .widget .widget-content .psycho-test.pieron .psycho-test-body table tr {
            background-color: #ffffff; }
            .old .mainbar .row .widget .widget-content .psycho-test.pieron .psycho-test-body table tr.question-wrapper td {
              border-color: transparent; }
              .old .mainbar .row .widget .widget-content .psycho-test.pieron .psycho-test-body table tr.question-wrapper td.answer span {
                width: auto;
                height: auto;
                padding: 0.25em; }
                .old .mainbar .row .widget .widget-content .psycho-test.pieron .psycho-test-body table tr.question-wrapper td.answer span.not-filled {
                  background-color: #cccccc; }
        .old .mainbar .row .widget .widget-content .e-acrdn h3 {
          clear: both;
          background-color: #12629F; }
          .old .mainbar .row .widget .widget-content .e-acrdn h3:hover {
            background-color: #3174A8; }
            .old .mainbar .row .widget .widget-content .e-acrdn h3:hover span {
              color: #FFFFFF; }
          .old .mainbar .row .widget .widget-content .e-acrdn h3 a, .old .mainbar .row .widget .widget-content .e-acrdn h3 a:hover, .old .mainbar .row .widget .widget-content .e-acrdn h3 span {
            color: #FFFFFF; }
        .old .mainbar .row .widget .widget-content .e-acrdn fieldset {
          border: 0; }
        .old .mainbar .row .widget .widget-content .back {
          padding-top: 1.25em; }
      .old .mainbar .row .widget .widget-foot {
        background-color: #e5e5e5;
        border-top: 0;
        padding: 0.5em 1em; }
  .old .profile-background::before {
    content: "";
    top: 0px;
    left: -50px;
    min-width: 120vw;
    min-height: 115vh;
    position: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.1);
    overflow: hidden; }
  .old .profile-background {
    background-position: bottom;
    top: 0px;
    left: -50px;
    min-width: 120vw;
    min-height: 115vh;
    position: fixed;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url("../images/loginBackground4.jpg");
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/loginBackground4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden; }
    .old .profile-background .login-center {
      color: rgba(0, 0, 0, 0.6);
      -webkit-animation: loginShow 1s ease-out 1s forwards;
      animation: loginShow 1s ease-in-out 1s forwards;
      position: fixed;
      z-index: 10001;
      text-align: left;
      border-radius: 10px;
      top: 60%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 50vw;
      max-width: 28rem;
      min-width: 20rem;
      opacity: 0;
      background: rgba(255, 255, 255, 0.9);
      padding: 3.2vh 1.6vw;
      overflow: hidden; }

@-webkit-keyframes loginShow {
  0% {
    top: 60%;
    opacity: 0; }
  100% {
    top: 50%;
    opacity: 1; } }

@keyframes loginShow {
  0% {
    top: 60%;
    opacity: 0; }
  100% {
    top: 50%;
    opacity: 1; } }
      .old .profile-background .login-center button {
        font-family: Raleway-Bold;
        font-size: 16px;
        color: #fff;
        line-height: 1.2;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding: 0 20px;
        min-width: 150px;
        height: 55px;
        background-color: #333333;
        border-radius: 50px;
        -webkit-transition: all 0.4s;
        transition: all 0.4s; }
      .old .profile-background .login-center input {
        font-family: Raleway-Medium;
        line-height: 1.2;
        font-size: 18px;
        display: block;
        width: 100%;
        background: transparent;
        height: 30px;
        padding: 0 25px 0 25px;
        color: rgba(0, 0, 0, 0.6);
        border-radius: 2px; }
        .old .profile-background .login-center input.baseInputStyle {
          height: 45px; }
      .old .profile-background .login-center span.loginTitle {
        font-family: Raleway;
        text-transform: uppercase;
        padding-bottom: 11px;
        text-align: left;
        width: 100%;
        font-size: 30px;
        line-height: 1.2;
        display: block; }
      .old .profile-background .login-center .card-container.card {
        width: 100%;
        background: transparent;
        position: relative;
        margin: 0;
        border-radius: 10px;
        padding: 0px; }
        .old .profile-background .login-center .card-container.card .form-group {
          margin: 1em 0; }
        .old .profile-background .login-center .card-container.card .ribbon {
          position: absolute;
          right: -0.3em;
          top: -0.3em;
          z-index: 1;
          overflow: hidden;
          width: 10rem;
          height: 6rem;
          text-align: right; }
          .old .profile-background .login-center .card-container.card .ribbon span {
            color: #FFFFFF;
            text-transform: uppercase;
            text-align: center;
            line-height: 25px;
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
            width: 120px;
            display: block;
            background: #12629F;
            background: -webkit-gradient(linear, left top, left bottom, from(#12629F), to(#0A4979));
            background: linear-gradient(#12629F 0%, #0A4979 100%);
            -webkit-box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.15);
                    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.15);
            position: absolute;
            top: 22px;
            right: -26px; }
            .old .profile-background .login-center .card-container.card .ribbon span::before {
              content: '';
              position: absolute;
              left: 0;
              top: 100%;
              z-index: -1;
              border-left: 3px solid #12629F;
              border-right: 3px solid transparent;
              border-bottom: 3px solid transparent;
              border-top: 3px solid #12629F; }
            .old .profile-background .login-center .card-container.card .ribbon span::after {
              content: '';
              position: absolute;
              right: 0;
              top: 100%;
              z-index: -1;
              border-right: 3px solid #12629F;
              border-left: 3px solid transparent;
              border-bottom: 3px solid transparent;
              border-top: 3px solid #12629F; }
        .old .profile-background .login-center .card-container.card .profile-img-card {
          width: 6rem;
          height: 6rem;
          margin: 0 auto 0.5em;
          display: block;
          border: 0.025em solid #676767;
          border-radius: 50%; }
        .old .profile-background .login-center .card-container.card .sessionTimeout {
          display: inherit;
          padding-bottom: 0.5em;
          color: #B12B2B;
          text-align: center; }
  .old .sweet-alert #passwordChangeModal input.form-control {
    display: inherit; }
  .old .sweet-alert input.e-input {
    height: 100% !important;
    border: 0 !important;
    margin: 0 !important;
    width: 100% !important; }
  .old .sweet-alert .e-widget {
    width: 100%;
    margin-bottom: 1em; }
  .old .sweet-alert .sa-input-error {
    top: 1.1em !important; }
  .old .sweet-alert .sa-input-error.show::before, .old .sweet-alert ::after {
    visibility: hidden !important; }
  .old .sweet-alert .sa-error-container {
    margin-bottom: 1.2em !important;
    background-color: #e5e5e5; }
    .old .sweet-alert .sa-error-container p {
      color: #B12B2B; }
  .old .sweet-alert button:focus::before, .old .sweet-alert button.cancel:focus::before {
    border: 0 !important; }
  @media (max-width: 1200px) {
    .old form.filter .cts-form-group.chkbox label {
      left: 35px;
      top: 4px; }
    .old form.filter .cts-form-group.chkbox > div {
      position: relative;
      bottom: 25px; } }
  @media (min-width: 480px) {
    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body {
      width: 100%; } }
  .old #userNavSM {
    display: none; }
  @media (max-width: 470px) {
    .old #userNav {
      display: none; }
    .old #userNavSM {
      display: block; }
    .old .dropdown-menu {
      left: -105px; } }
  @media (max-width: 767px) {
    .old .sideMenuOpenedButton {
      display: none !important; }
    .old .sideMenuOpened {
      left: 0 !important; }
    .old .sideMenuOpenedContent .mainbar {
      margin-left: 0 !important; }
    .old .sideMenuClosedContent .mainbar {
      margin-left: 0 !important; }
    .old .sideMenuOpened .sidebar-filler {
      left: 0 !important; }
    .old .sideMenuClosedButton {
      display: none !important; }
    .old .sideMenuClosed {
      left: 0 !important; }
    .old .sideMenuClosed .sidebar-filler {
      left: 0 !important; }
    .old .sidebar-include {
      position: relative !important;
      float: none !important;
      height: auto !important; }
    .old .cts-logo .logo {
      display: none; }
    .old .sidebar {
      float: none;
      width: 100%;
      position: fixed;
      z-index: 10000;
      height: auto; }
      .old .sidebar .sidebar-dropdown {
        display: block; }
      .old .sidebar .sidebar-inner {
        height: auto;
        overflow: hidden;
        display: none; }
      .old .sidebar .sidebar-filler {
        display: none; }
    .old .mainbar {
      margin: 0;
      float: none; }
    .old .row {
      margin-top: 50px; }
    .old #athletesList_container {
      z-index: 1; } }
  @media (min-width: 768px) {
    .old .sidebar .sidebar-inner {
      display: block !important; }
    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body {
      width: 100%; } }
  @media (min-width: 992px) {
    .old, .old body {
      /*form {
            &.filter {
                .cts-form-group {
                    width: 50%;
                    float: left;
                    padding: 0 1em;

                    &.chkbox {
                        width: auto;

                        label {
                            position: relative;
                            left: 23px;
                        }
                    }

                    > div {
                        padding: 0;
                    }

                    &:nth-of-type(2n + 1) {
                        clear: left;
                    }
                }
            }
        }*/ }
      .old .mainbar .row .widget .widget-content .button-wrapper {
        width: auto;
        text-align: left; }
      .old form:not(.filter):not(.popup) .cts-form-group label {
        width: 33.33333333%;
        float: left; }
      .old form:not(.filter):not(.popup) .cts-form-group > div {
        width: 66.66666667% !important;
        float: left;
        padding-left: 1em; }
      .old form:not(.filter):not(.popup) .cts-form-group.inputonly {
        margin-bottom: 0 !important; }
        .old form:not(.filter):not(.popup) .cts-form-group.inputonly > div {
          width: 100% !important;
          padding-right: 1em !important;
          padding-left: 0 !important; }
        .old form:not(.filter):not(.popup) .cts-form-group.inputonly label {
          display: none !important; } }
  @media (min-width: 1200px) {
    .old form:not(.filter):not(.popup) .cts-form-group label {
      width: 50%;
      float: left; }
    .old form:not(.filter):not(.popup) .cts-form-group > div {
      width: 50% !important;
      float: left;
      padding-left: 1em; }
    .old form:not(.filter):not(.popup) .cts-form-group.inputonly {
      margin-bottom: 0 !important; }
      .old form:not(.filter):not(.popup) .cts-form-group.inputonly > div {
        width: 100% !important;
        padding-right: 1em !important;
        padding-left: 0 !important; }
      .old form:not(.filter):not(.popup) .cts-form-group.inputonly label {
        display: none !important; }
    .old form.filter .cts-form-group {
      width: 25%;
      float: left;
      padding: 0 1em; }
      .old form.filter .cts-form-group.chkbox {
        width: auto; }
        .old form.filter .cts-form-group.chkbox label {
          width: 175px; }
      .old form.filter .cts-form-group > div {
        padding: 0; }
      .old form.filter .cts-form-group:nth-of-type(2n + 1) {
        clear: none; }
      .old form.filter .cts-form-group:nth-of-type(4n + 1) {
        clear: none; }
    .old .mainbar .row .widget .widget-content .image-preview {
      max-width: inherit; }
    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body {
      width: 75%; }
    .old .image-preview {
      float: left;
      clear: both; }
    .old form.listboxkozepre .cts-form-group label {
      width: 25% !important; }
    .old form.listboxkozepre .cts-form-group > div.input-group {
      width: 75% !important; } }
  @media (min-width: 1600px) {
    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body {
      width: 60%; }
    .old .column-left {
      min-width: 16.7%; }
    .old form.listboxkozepre .cts-form-group label {
      width: 50% !important; }
    .old form.listboxkozepre .cts-form-group > div.input-group {
      width: 50% !important; } }
  .old .drop-in-pager div, .old .drop-in-pager2 div {
    display: inline-block; }
  .old .drop-in-pager div:first-child, .old .drop-in-pager2 div:first-child {
    position: relative;
    bottom: 5px; }
  .old .drop-in-pager, .old .drop-in-pager2 {
    float: right;
    position: relative;
    right: 20px;
    top: 3px; }
  .old .drop-in-pager [class^="e-"], .old .drop-in-pager2 [class^="e-"] {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
  .old .profileImg .cts-button {
    position: absolute;
    left: 125px;
    top: -90px; }
    .old .profileImg .cts-button.cancel {
      top: -50px; }
  .old .profileImg .disabled {
    opacity: 0.5;
    background-color: #eee; }
  .old .profileImg img {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    max-width: 200px;
    max-height: 200px; }
  .old .modal-body p {
    font-size: 1.2em; }
  .old .modal-footer {
    padding: 15px 0 15px 0 !important; }
  .old .e-title i {
    font-size: 1.2em; }
  .old .dialogContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    visibility: hidden;
    z-index: 20000001; }
  .old .modalCell {
    display: table-cell;
    vertical-align: middle; }
  .old .cropDialog .modal-body {
    padding: 0 !important; }
  .old .havijelenletigrid .e-input {
    padding: 0;
    text-align: center !important; }
  .old .row {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    .old .row .cell {
      width: 50%;
      float: left; }
    .old .row .cell:first-of-type {
      padding-left: 0 !important; }
    .old .row .cell:last-of-type {
      padding-right: 0 !important; }
  .old .gridheaderverticalcenter {
    vertical-align: bottom;
    text-align: center; }
  .old .rotate90degreegridheader {
    vertical-align: bottom; }
    .old .rotate90degreegridheader .e-headercelldiv {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      text-align: center !important;
      width: 120px !important;
      height: 14px !important;
      text-overflow: unset !important; }
      .old .rotate90degreegridheader .e-headercelldiv[data-ej-mappingname="SzuletesiDatum"] {
        padding-top: 0.75em; }
  .old .selected-row {
    background-color: #5EABDE !important;
    color: green; }
  .old .rotate90degreegridheader2 .e-headercelldiv {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: 50% 50% !important;
            transform-origin: 50% 50% !important;
    text-align: center !important;
    margin-bottom: 0 !important;
    width: 150px !important;
    height: 150px !important; }
  .old .rotate90degreegridheader3 .e-headercelldiv {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-align: left !important;
    margin-bottom: 0 !important;
    width: 150px !important;
    height: 150px !important;
    padding-left: 2em; }
  .old .rotate90degreegridheader4 .e-headercelldiv {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-align: left !important;
    margin-bottom: 20px !important;
    width: 150px !important;
    height: 130px !important; }
  .old .dialog-hide-elemet {
    display: none !important; }
  .old .table-no-margin .form-group {
    margin: 0 !important;
    padding: 0 !important; }
  .old .gridEditTemplateTextAndButton {
    float: left;
    width: 87%; }
    .old .gridEditTemplateTextAndButton > span, .old body .gridEditTemplateTextAndButton > span, .old .e-button:hover .gridEditTemplateTextAndButton > span, .old .e-button:hover body .gridEditTemplateTextAndButton > span {
      float: left;
      width: 100%; }
  .old .msgtools.newmsg {
    background-image: url("../images/Uzenetek/ujUzenet.png");
    width: 32px;
    height: 32px;
    vertical-align: center;
    background-repeat: no-repeat; }
  .old .msgtools.msgreply {
    background-image: url("../images/Uzenetek/valasz.png");
    width: 32px;
    height: 32px;
    vertical-align: center;
    background-repeat: no-repeat; }
  .old .msgtools.msgforward {
    background-image: url("../images/Uzenetek/tovabbitas.png");
    width: 32px;
    height: 32px;
    vertical-align: center;
    background-repeat: no-repeat; }
  .old .msgtools.msgdelete {
    background-image: url("../images/Uzenetek/torol.png");
    width: 32px;
    height: 32px;
    vertical-align: center;
    background-repeat: no-repeat; }
  .old .msgtools.msgprint {
    background-image: url("../images/Uzenetek/nyomtatas.png");
    width: 32px;
    height: 32px;
    vertical-align: center;
    background-repeat: no-repeat; }
  .old .msgtools.msgrefresh {
    background-image: url("../images/Uzenetek/frissites.png");
    width: 32px;
    height: 32px;
    vertical-align: center;
    background-repeat: no-repeat; }
  .old .msgtools.newSend {
    background-image: url("../images/Uzenetek/kuld.png");
    width: 32px;
    height: 32px;
    vertical-align: center;
    background-repeat: no-repeat; }
  .old .msgtools.Back {
    background-image: url("../images/Uzenetek/Back.png");
    width: 32px;
    height: 32px;
    vertical-align: center;
    background-repeat: no-repeat; }
  .old .msgtools.msgCopy {
    background-image: url("../images/Uzenetek/Copy.png");
    width: 32px;
    height: 32px;
    vertical-align: center;
    background-repeat: no-repeat; }
  .old .msgtools.msgCut {
    background-image: url("../images/Uzenetek/Cut.png");
    width: 32px;
    height: 32px;
    vertical-align: center;
    background-repeat: no-repeat; }
  .old .msgtools.msgPaste {
    background-image: url("../images/Uzenetek/Paste.png");
    width: 32px;
    height: 32px;
    vertical-align: center;
    background-repeat: no-repeat; }
  .old .msgtools.Accept {
    background-image: url("../images/Uzenetek/elfogad.png");
    width: 32px;
    height: 32px;
    vertical-align: center;
    background-repeat: no-repeat; }
  .old .msgtools.msgrcvd {
    background-image: url("../images/Uzenetek/rcvd.png");
    width: 32px;
    height: 32px;
    vertical-align: center;
    background-repeat: no-repeat; }
  .old .msgtools.msgsent {
    background-image: url("../images/Uzenetek/sent.png");
    width: 32px;
    height: 32px;
    vertical-align: center;
    background-repeat: no-repeat; }
  .old .scroller-fix-width100 > .input-group, .old body .scroller-fix-width100 > .input-group {
    overflow: auto;
    width: 100% !important; }
  .old .e-rowcell .e-rating .e-ul {
    width: 190px !important; }
  @media print {
    .old body * {
      display: none; }
    .old body .printable {
      display: block; } }
  @media (min-width: 1200px) {
    .old .auto-clear .col-lg-1:nth-child(12n+1) {
      clear: left; }
    .old .auto-clear .col-lg-2:nth-child(6n+1) {
      clear: left; }
    .old .auto-clear .col-lg-3:nth-child(4n+1) {
      clear: left; }
    .old .auto-clear .col-lg-4:nth-child(3n+1) {
      clear: left; }
    .old .auto-clear .col-lg-6:nth-child(odd) {
      clear: left; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .old .auto-clear .col-md-1:nth-child(12n+1) {
      clear: left; }
    .old .auto-clear .col-md-2:nth-child(6n+1) {
      clear: left; }
    .old .auto-clear .col-md-3:nth-child(4n+1) {
      clear: left; }
    .old .auto-clear .col-md-4:nth-child(3n+1) {
      clear: left; }
    .old .auto-clear .col-md-6:nth-child(odd) {
      clear: left; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .old .auto-clear .col-sm-1:nth-child(12n+1) {
      clear: left; }
    .old .auto-clear .col-sm-2:nth-child(6n+1) {
      clear: left; }
    .old .auto-clear .col-sm-3:nth-child(4n+1) {
      clear: left; }
    .old .auto-clear .col-sm-4:nth-child(3n+1) {
      clear: left; }
    .old .auto-clear .col-sm-6:nth-child(odd) {
      clear: left; } }
  @media (max-width: 767px) {
    .old .auto-clear .col-xs-1:nth-child(12n+1) {
      clear: left; }
    .old .auto-clear .col-xs-2:nth-child(6n+1) {
      clear: left; }
    .old .auto-clear .col-xs-3:nth-child(4n+1) {
      clear: left; }
    .old .auto-clear .col-xs-4:nth-child(3n+1) {
      clear: left; }
    .old .auto-clear .col-xs-6:nth-child(odd) {
      clear: left; } }
  .old .e-grid .e-icon-calender::before {
    width: 15px !important;
    content: '\e7b5'; }

.messenger .container {
  width: auto;
  min-height: calc(100% - 140px);
  max-height: calc(100% - 140px);
  margin: 10px;
  padding: 0px;
  border: 1px solid #e5e5e5; }

.messenger .mainbody, .messenger .header, .messenger .footer {
  padding: 10px 15px; }

.messenger .mainbody {
  margin-top: 0;
  min-height: 500px;
  max-height: 500px;
  overflow: auto; }

.messenger .header {
  border-bottom: 1px solid #EEE;
  background-color: #FFFFFF;
  height: 60px;
  padding: 10px; }
  .messenger .header p {
    font-size: medium;
    font-weight: bold;
    text-align: center; }

.messenger .footer {
  height: 60px; }

.messenger-message-item pre, .messenger-message-item p.message {
  white-space: pre-wrap;
  float: left;
  margin: 0;
  padding: 10px;
  min-height: 35px; }

.messenger-message-item img {
  width: 50px;
  float: left;
  margin: 0;
  padding: 0px;
  border-radius: 50%; }

.messenger-message-item p.nev {
  margin: 5px 0 5px 55px; }

.messenger-message-item .message-wrapper {
  float: left;
  width: calc(100% - 140px);
  margin: 0 35px 0 5px; }
  .messenger-message-item .message-wrapper .message-images {
    float: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 300px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    clear: both; }
    .messenger-message-item .message-wrapper .message-images .message-image img {
      border-radius: 10px;
      padding: 2px;
      width: 60px;
      height: 60px;
      -webkit-transition: .3s all linear;
      transition: .3s all linear; }

.messenger-message-item p.message {
  padding: 0.5em 1em;
  background-color: #99bdd8;
  color: #FFFFFF;
  border-radius: 1em;
  width: auto;
  max-width: 75%;
  min-height: 35px; }

.messenger-message-item p.datum {
  font-size: 13px;
  font-style: italic; }

.messenger-message-item p:not(.message) {
  clear: left; }

.messenger-message-item.sender img.felado, .messenger-message-item.sender p.nev {
  visibility: hidden; }

.messenger-message-item.sender p.datum {
  text-align: right;
  clear: right;
  float: right;
  font-size: 13px;
  font-style: italic; }

.messenger-message-item.sender .message-wrapper {
  margin: 0 5px 0 85px; }
  .messenger-message-item.sender .message-wrapper .message-images {
    float: right;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }

.messenger-message-item.sender p.message {
  background-color: #3174A8;
  color: #FFFFFF;
  text-align: right;
  float: right;
  border-radius: 1em;
  min-height: 35px; }

.messenger-message-item:not(.sender) img.kuldo {
  visibility: hidden; }

#directory #toolbarPopup {
  width: 877px;
  border: 1px solid #c8c8c8; }

#directory #defaultTab {
  width: 875px;
  border-left: 1px solid #c8c8c8;
  border-right: 1px solid #c8c8c8; }
  #directory #defaultTab ul li {
    border: none;
    min-width: 275px; }
    #directory #defaultTab ul li img {
      margin-right: 10px; }
  #directory #defaultTab ul .e-active {
    background: #BED5E6; }

.counterBadge {
  position: relative;
  float: right;
  text-align: center;
  width: 22px;
  height: 22px;
  background-color: #508DBB;
  border-radius: 50%;
  font-size: 14px;
  right: 40px; }

.disabledMenuItem {
  background-color: #676767 !important;
  pointer-events: none; }
  .disabledMenuItem:hover {
    background-color: #676767 !important; }
  .disabledMenuItem a {
    background-color: #676767 !important; }

#issueDialog_wrapper {
  top: 25vh !important; }
